h1 {
	text-align: center;
	font-size:3em;
	border-bottom: thin solid grey;
	padding-bottom: 10px;
	margin-top: 0px;
	font-family: Arial, Helvetica, sans-serif;
}

.LetterBox {
	border: 3px solid lightgrey;
	height: 70px;
	width: 70px;
	box-sizing: border-box;
	text-align: center;
	font-size: x-large;
	font-family: Arial, Helvetica, sans-serif;
	align-items: center;
	justify-content: center;
	display:flex;
	margin: 5px;
}

.noLetter {
	border-color: lightgrey;
}

.letterPlaced {
	border-color: rgb(85, 85, 85);
}

.correctPosition {
	border-color: rgb(28, 163, 28);
}

.correctLetter {
	border-color: rgb(226, 157, 30)
}

.incorrectLetter {
	border-color: rgb(226, 38, 38);
}

.WordRow {
	display: inline-flex;
	min-width: 100%;
	align-items:center;
	justify-content: center;
}

.keyboard {
	margin-top:50px;
}

.keys {
	margin-right: 5px;
	margin-bottom: 4px;
	font-size:x-large;
	min-width: 50px;
	background-color: whitesmoke;
	border: thin solid darkgray;
	border-radius: 3px;
}

.keysUnknown {
	background-color: whitesmoke;
}

.keysIncorrect {
	background-color: rgb(226, 38, 38);
}

.keysCorrectLetter {
	background-color: rgb(226, 157, 30);
}

.keysCorrectPosition {
	background-color: rgb(28, 163, 28);
}

.keys:hover {
	background-color: grey;
}

.keyRow {
	display:flex;
	align-items: center;
	justify-content: center;
}

.endGame {
	border: thick solid black;
	background-color: whitesmoke;
	border-radius: 10px;
	height: 400px;
	width: 300px;
	position: absolute;
	text-align: center;
	justify-items: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	box-shadow: darkgray 0px 0px 10px 10px;
	font-family: Arial, Helvetica, sans-serif;
	animation-name: slidein;
	animation-duration: 2s;
}

@keyframes slidein {
	from {
		top: -200px;
	}

	to {
		top: 50%;
	}
}

.messageHeader {

	margin-top: 50px;;
	font-family:Arial, Helvetica, sans-serif;
	font-size: 2em;
	color:rgba(87, 155, 104, 0.884);
	text-shadow: -1px 0 black,
	0 1px black,
	1px 0 black,
	0 -1px black;

}

.message {

	font-size: x-large;
	margin-top: 40px;
}

.displayWord {

	text-align: center;
	height:25px;
	letter-spacing: 4px;
}

.notAWord {
	text-align: center;
	color: rgb(226, 38, 38);
	margin:0px;
	font-size:1.5em;
	font-family: Arial;
	font-style:italic;
	font-weight: bold;
	height: 20px;
}

.playAgainButton {

	width:160px;
	height: 60px;
	background-color:lightgrey;
	border-radius: 8px;
	color:rgb(97, 97, 97);
	font-size: x-large;
	font-family: fantasy;
	letter-spacing: 2px;
	margin-top: 20px;;
}

.playAgainButton:Hover {

	color:rgb(63, 63, 63);
	background-color: rgb(150, 150, 150);
}

@media only screen and (max-width: 600px) {
	.LetterBox {
		height: 50px;
		width: 50px;
		margin: 2px;
	}

	.keys {
		min-width:30px;
		font-size: large;
	}
}
